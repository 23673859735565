import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Slice
import { setActiveLoginModal, setLms } from '../../app/features/userSlice';
// Components
import Container from '../Container';
// Services
import { getSectionsInstitutions } from '../../services/getSectionsInstitutions';
// Images
import { IoMdClose } from 'react-icons/io';
import imageLogin from '../../Images/Login/loginImage.webp';
// Styles
import './LoginModal.css';
import { autenticationURL } from '../../services/Login/getInstitutionData';

const LoginModal = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { institutionCode, assignedLogin, ebooksAccess } = useSelector(
    (state) => state.institution
  );

  const dataMetasearcher = getSectionsInstitutions(
    institutionCode,
    ebooksAccess
  )[0].data;
  const dataIndependient = getSectionsInstitutions(
    institutionCode
  )[1].data.filter((objeto) => objeto.type === 'independient');

  const data = [...dataMetasearcher, ...dataIndependient];

  const loginButtonName = (name) => {
    return name === 'unique' ? 'Unique Login' : `Login with ${name}`;
  };

  const getLogin = (login) => {
    dispatch(setActiveLoginModal(false));
    if (login === 'unique' || login === 'talent') {
      navigate('login');
    } else if (login === 'canvas' || login === 'moodle') {
      dispatch(setLms(login));
      window.location.href = autenticationURL(login, institutionCode);
    }
  };

  return (
    <div className='loginHomeModal'>
      <Container>
        <div className='modal-content'>
          <div className='textContent'>
            <div>
              <h2>Access hundreds of Academic Documents</h2>
              <p>
                Our platform puts a world of knowledge at your fingertips,
                providing you with the tools and resources you need to succeed
                in your studies.
              </p>
            </div>

            <div className='modalResources'>
              {data.map((section, i) => {
                return (
                  <div key={i}>
                    <img src={section.icon} alt={section.title} />
                    <p>{section.description}</p>
                  </div>
                );
              })}
            </div>
            <div className='loginButtonModalContainer'>
              {assignedLogin.map((login, i) => {
                return (
                  <button
                    onClick={() => getLogin(login)}
                    key={i}
                    className='loginButtonModal'
                  >
                    {loginButtonName(login)}
                  </button>
                );
              })}
            </div>
          </div>
          <div className='imageContent'>
            <div className='gradiente'></div>
            <img src={imageLogin} alt='Modal login' />
          </div>
          <button
            className='closeButton'
            onClick={() => dispatch(setActiveLoginModal(false))}
          >
            <IoMdClose />
          </button>
        </div>
      </Container>
    </div>
  );
};

export default LoginModal;
