import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
// Components
import Container from '../../Components/Container';
// Styles
import './HelpPage.css';

const HelpPage = () => {
  const { institutionCode } = useSelector((state) => state.institution);
  return (
    <main className='help'>
      <Container>
        <div className='helpContainer'>
          <div className='helpContailer__FAQs'>
            <h1>OLiB FAQ’s</h1>
            <div className='customServices'>
              <p>
                <b>How to contact us?</b>
              </p>
              {institutionCode !== 'HCAS' && (
                <p>
                  <b>Customer service number:</b> +1 (954) 858 6482
                </p>
              )}

              <div className='customServicesActions'>
                {institutionCode === 'HCAS' ? (
                  <a
                    href='mailto:library@dp.hcas.edu'
                    className='customServicesbutton hcas'
                  >
                    Contact our librarian
                  </a>
                ) : (
                  <>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='tel:+19548586482'
                      className='customServicesbutton'
                    >
                      CALL
                    </a>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://wa.me/+19548586482?text=Hi, I am interested in Online Library services.'
                      className='customServicesbutton'
                    >
                      WHATSAPP
                    </a>
                  </>
                )}
              </div>
            </div>
            <details>
              <summary className='questionFAQs'>What is OLiB?</summary>
              <p className='questionAnswer'>
                OLiB is an online library designed to provide academic content
                in different formats.
              </p>
              <div className='questionAnswer containerVideoHelp'>
                <ReactPlayer
                  url='https://player.vimeo.com/video/857661585?h=7a7fd7c83f?rel=0'
                  controls
                  width='100%'
                  height='180px'
                ></ReactPlayer>
                <ReactPlayer
                  url='https://player.vimeo.com/video/857661537?h=101604f111?rel=0'
                  controls
                  width='100%'
                  height='180px'
                ></ReactPlayer>
              </div>
            </details>
            <details>
              <summary className='questionFAQs'>
                How can I find a book from the library?
              </summary>
              <p className='questionAnswer'>
                You can use the online catalog and request it. If the item is
                located in another campus library, you can brought it through
                Interlibrary Loan to this section where the form and procedures
                are.
              </p>
            </details>
            <details>
              <summary className='questionFAQs'>
                How can I make a search to find information for my paper?
              </summary>
              <p className='questionAnswer'>
                There are many resources in OLiB that you can use according to
                the topic you selected. If you are not familiar with your topic
                it will be good if you start your search using a dictionary.
                Once you get familiar with the terms it will be easier to find
                the most relevant resource to start your search.
              </p>
            </details>
            <details>
              <summary className='questionFAQs'>
                Are there any guides for writing a paper?
              </summary>
              <p className='questionAnswer'>
                You can go to{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://extras.apa.org/apastyle/basics-7e/?_ga=2.155904504.238527057.1664917899-1131865895.1664917899#/'
                >
                  Academic Writer
                </a>
                . This is a tutorial that provides basic instructions on APA
                Style seventh Edition. Also, you can use Purdue OwL
              </p>
            </details>
            <details>
              <summary className='questionFAQs'>
                How can I get a citation tool?
              </summary>
              <p className='questionAnswer'>
                Zotero
                <br />
                Mendeley
              </p>
            </details>
            {institutionCode === 'CMVC' && (
              <details>
                <summary className='questionFAQs'>
                  Where do I go if I want to get more tutoring for my classes?
                </summary>
                <p className='questionAnswer'>
                  The tutoring schedule can be located on the main page of the
                  OLiB or by clicking <Link to='/tutoring'>here</Link>. There is
                  also availability to tutoring through{' '}
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.tutor.com/'
                  >
                    tutor.com
                  </a>
                  . If a student is requesting additional resources they should
                  visit their student services coordinator at their campus.
                </p>
              </details>
            )}
          </div>
        </div>
      </Container>
    </main>
  );
};

export default HelpPage;
