// IMAGE SECTIONS
import art from '../Images/HomePage/Sections/artSection.webp';
// import article from '../Images/HomePage/Sections/article.png';
// import audio from '../Images/HomePage/Sections/audio.png';
import books from '../Images/HomePage/Sections/booksSection.webp';
import dictionary from '../Images/HomePage/Sections/dictionarySection.webp';
import job from '../Images/HomePage/Sections/job.png';
import articles from '../Images/HomePage/Sections/articlesSection.webp';
import media from '../Images/HomePage/Sections/media.png';
import news from '../Images/HomePage/Sections/newsSection.webp';
import patents from '../Images/HomePage/Sections/patentsSection.webp';
import interestingLinks from '../Images/HomePage/Sections/interestingLinksSection.webp';
import softSkillsSection from '../Images/HomePage/Sections/softSkillsSection.webp';
// ICONS SECTIONS
import artIcon from '../Images/Login/Modal/artIcon.svg';
import articlesIcon from '../Images/Login/Modal/articlesIcon.svg';
import dictionaryIcon from '../Images/Login/Modal/dictionaryIcon.svg';
import patentsIcon from '../Images/Login/Modal/patentsIcon.svg';
import newsIcon from '../Images/Login/Modal/newsIcon.svg';
import jobsIcon from '../Images/Login/Modal/jobsIcon.svg';
import interestingLinksIcon from '../Images/Login/Modal/interestingLinksIcon.svg';
import americanLifeIcon from '../Images/Login/Modal/americanLifeIcon.svg';
import instructionalVideosIcon from '../Images/Login/Modal/instructionalVideosIcon.svg';
import helpIcon from '../Images/Login/Modal/helpIcon.svg';
import ebookIcon from '../Images/Login/Modal/ebookIcon.svg';

// Icons section

export const getSectionsInstitutions = (institutionCode, ebooks = false) => {
  let sections = [
    {
      type: 'metasearcher',
      data: [
        {
          title: 'Articles',
          url: articles,
          route: 'articles',
          description: '16k+ library results',
          icon: articlesIcon,
          type: 'metasearcher',
        },
        {
          title: 'Books',
          url: books,
          route: 'books',
          description: '16k+ books results',
          icon: articlesIcon,
          type: 'metasearcher',
        },
        {
          title: 'Dictionary',
          url: dictionary,
          route: 'dictionary',
          description: 'More than 20k words from the dictionary',
          icon: dictionaryIcon,
          type: 'metasearcher',
        },
        {
          title: 'Patents',
          url: patents,
          route: 'patents',
          description: 'More than 20k Patent results',
          icon: patentsIcon,
          type: 'metasearcher',
        },
        {
          title: 'News',
          url: news,
          route: 'news',
          description: 'Recent and relevant global news.',
          icon: newsIcon,
          type: 'metasearcher',
        },
        {
          title: 'Media',
          url: media,
          route: 'media',
          description: 'Rich instructional videos',
          icon: instructionalVideosIcon,
          type: 'metasearcher',
        },
      ],
    },
    {
      type: 'independient',
      data: [
        {
          title: 'Job Hunter',
          url: job,
          route: 'jobs',
          description: 'More than 2k job offers',
          icon: jobsIcon,
          type: 'independient',
        },
        {
          title: 'Interesting Links',
          url: interestingLinks,
          route: 'interesting-links',
          description: 'Interesting Links',
          icon: interestingLinksIcon,
          type: 'independient',
        },
        {
          title: 'Soft skills and American life',
          url: softSkillsSection,
          route: 'american-life',
          description: 'Learn about Soft Skills and American life',
          icon: americanLifeIcon,
          type: 'independient',
        },
        {
          title: 'Tutorials',
          url: '',
          route: 'tutorials',
          description: 'Turotials',
          icon: interestingLinksIcon,
          type: 'independient',
        },
        {
          title: 'Help',
          url: '',
          route: 'help',
          description: 'Help',
          icon: helpIcon,
          type: 'independient',
        },
        {
          title: 'Search',
          url: '',
          route: 'search',
          description: 'Search',
          icon: '',
          type: 'none',
        },
      ],
    },
  ];

  ebooks &&
    sections[0].data.push({
      title: 'EBooks',
      url: books,
      route: 'ebooks',
      description: 'Ebooks',
      icon: ebookIcon,
      type: 'other',
    });

  (institutionCode === 'MTA' || institutionCode === 'DEMO') &&
    sections[1].data.unshift({
      title: 'Art',
      url: art,
      route: 'art',
      description: 'More than 10k art stories',
      icon: artIcon,
      type: 'independient',
    });

  return sections;
};
