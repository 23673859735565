import { fetchWithoutToken } from '../utils/fetch';

export const getInfoUserCanvas = async (codeParam, institutionCode) => {
	const getCredentials = () => {
		switch (institutionCode) {
			case 'CMVC':
				return {
					client_id: process.env.REACT_APP_CLIENTID_CMVC_CANVAS,
					client_secret: process.env.REACT_APP_CLIENTSECRET_CMVC_CANVAS,
					domain: 'compumed',
				};
			case 'HCAS':
				return {
					client_id: process.env.REACT_APP_CLIENTID_HCAS,
					client_secret: process.env.REACT_APP_CLIENTSECRET_HCAS,
					domain: 'hcas',
				};
			case 'CBT':
				return {
					client_id: process.env.REACT_APP_CLIENTID_CBT,
					client_secret: process.env.REACT_APP_CLIENTSECRET_CBT,
					domain: 'cbt',
				};
			case 'SABER':
				return {
					client_id: process.env.REACT_APP_CLIENTID_SABER,
					client_secret: process.env.REACT_APP_CLIENTSECRET_SABER,
					domain: 'sabercollege',
				};
			default:
				return {
					client_id: process.env.REACT_APP_CLIENTID_CMVC_CANVAS,
					client_secret: process.env.REACT_APP_CLIENTSECRET_CMVC_CANVAS,
					domain: 'compumed',
				};
		}
	};

	try {
		const res = await fetchWithoutToken.post(
			`/canvas`,
			{
				client_id: getCredentials().client_id,
				client_secret: getCredentials().client_secret,
				code: codeParam,
				base_url: `https://${getCredentials().domain}.instructure.com`,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.token}`,
				},
			}
		);
		return res;
	} catch (error) {
		console.error('Your authorization token has expired or is incorrect.');
		return null;
	}
};
